<template>
    <div>
        <customexport-form :entity-id="entityId"></customexport-form>
    </div>
</template>

<script>
import customexportForm from '@/components/pages/customExport/CreateForm'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: { customexportForm },

    mixins: [dirtyForm],

    data() {
        return {
            entityId: null,
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                this.entityId = route.params.id
            },
        },
    },
}
</script>
